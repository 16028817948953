import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Join from "./components/Join";
import Chat from "./components/Chat";
import Navbar from './components/Navbar';
import ImageGenerator from './components/ImageGenerator';

function App() {

  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" exact element={<Chat/>}  />
        {/* <Route path="/image" element={<ImageGenerator/>}/> */}
        <Route path="join" element={<Join/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
