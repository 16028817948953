import React, { useState } from "react";
import axios from "axios";
import "../App.css";
import { Configuration, OpenAIApi } from "openai";
import Alert from "../components/Alert";
import ReactMarkdown from 'react-markdown';
let ethanProfile = require("../images/jyds-ai-avatar.png");

const Chat = () => {
  const [message, setMessage] = useState("");
  const [thread, setThread] = useState([{ role: "system", m: "What's up!" }]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  

  const scrollToEnd = () => {
    return window.scrollTo(0, 100000000);
  };

  const handleSubmit = async (e) => {
    scrollToEnd();
    e.preventDefault();
    try {
      setLoading(true);
      setThread([
        ...thread,
        { role: "user", m: message },
        { role: "system", m: "Generating Response..." },
      ]);
      // new code
      const configuration = new Configuration({
        apiKey: "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",
      });
      const openai = new OpenAIApi(configuration);

      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `You are AI ChatYard, a very funny AI assistant created by JunkYard Dogs Sol. You are full of humour in every answer.\n\n about jyds: our yard's currency is 100% decentralized to the dogs in the yard. There are no team tokens and 90% of the funds being raised are going towards the LP for a fair and healthy launch. Take a look at the full breakdown below.

            Total Supply 500M $JYDS
            Presale/LP: 90%
            CEX/Staking/Utility reserves: 10%
            Tax: 0/0
            
            
            /n/n
          users can check presale address and further information about buying the jyds token on our website www.jyds.tech`,
          },
          {
            role: "user",
            content: `${message}`
          }
        ],
      });

      setThread([
        ...thread,
        { role: "user", m: message },
        { role: "system", m: <ReactMarkdown>{completion.data.choices[0].message.content}</ReactMarkdown> },
      ]);

      setLoading(false);
      setMessage("");
      scrollToEnd();
    } catch (error) {
      console.log(error);
    }
  };

  function chatResponse() {
    // if (loading) {
    //   document.getElementById("overlay").style.display = "block";
    // }else if(!loading){
    //   document.getElementById("overlay").style.display = "none";
    // }
    return thread.map((chatMessage, index) => {
      if (chatMessage.role === "user") {
        // User message
        return (
          <div className="chat-user-container" key={index}>
            <pre className="user-message">{chatMessage.m}</pre>
          </div>
        );
      } else {
        // System message
        return (
          <div className="chat-response-container" key={index}>
            <img alt="ethan" src={ethanProfile} />
            <div className="chat-response system-message">{chatMessage.m}</div>
          </div>
        );
      }
    });
  }

  return (
    <>
    {loading && <div id="overlay">Loading...</div>}
      {/* <div id="overlay">
        <span>
          
        </span>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {/* <Alert /> */}
            {chatResponse(thread)}
          </div>
        </div>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="input-group mb-3">
          <div className="w-100">
            <input
              type="text"
              id="chatInput"
              className=""
              placeholder="Enter Your Message"
              value={message}
              onChange={handleChange}
            />
            <button className="" type="submit" id="sendMessage">
              Send
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Chat;
