import React from 'react';
let jydsLogo = require('../images/jyds-chatyard.png');

const Navbar = () => {
    const toggleNav = ()=>{
        document.getElementById("navbarSupportedContent").classList.toggle("collapse");
    }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
  <a className="navbar-brand" href="https://jyds.tech/">
    <img src={jydsLogo} alt="logo" />
  </a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="https://jyds.tech/">JYDS Website</a>
      </li>
      </ul>
    </div>
  <button onClick={toggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
  </div>
</nav>
  )
}

export default Navbar